
<template>

  <section class="todo-list">
    <form @submit.prevent="addItem">
      <div class="add-items d-lg-flex">
        <input v-model="itemName" required type="text" class="form-control todo-list-input mright"  :placeholder="placeholder_name">
        <input v-model="itemAmount" v-number-format required type="text" class="form-control todo-list-input" :placeholder="placeholder_amount">
        <submit-button :loading_flag="loading" :label="add_text"/>
        
      </div>
    </form>
    <div class="list-wrapper">
      <ul class="d-flex flex-column-reverse quoter-list todo-list">
        <li v-for="(item,index) in items" :key="item.id">
          <div class="form-check">
            <label class="form-check-label" v-if="!item.editing">
            <!--<input class="checkbox" v-model="item.active" type="checkbox">-->{{item.name}}<i class="input-helper"></i> <span class="float-right">{{item.amount}}</span>
            </label>
            
            <div class="editing-quote">
              <input v-if="item.editing" type="text" v-model="item.name" class="form-control"/>
              <input v-if="item.editing" type="text" v-number-format v-model="item.amount" class="form-control float-right"/>
            </div>
        
          </div>

          <div class="float-right task-options">
            <span v-if="!item.editing">
                <a @click="editItem(index)" class="action-link" href="javascript:void(0)"><i class="mdi mdi-square-edit-outline"></i></a>
              <a @click="deleteItem(index)" class="action-link" href="javascript:void(0)"><i class="mdi mdi-close-circle-outline"></i></a>
            </span>
            <span v-if="item.editing">
                <a @click="updateItem(index)" class="action-link" href="javascript:void(0)"><i class="mdi mdi-check"></i></a>
                <a @click="cancelEdit(index)" class="action-link" href="javascript:void(0)"><i class="mdi mdi-cancel"></i></a>
            </span>
          </div>

        </li>
      </ul>
    </div>
  </section>

</template>

<script lang="js">

import submitButton from '@/components/ui-feature/submit-button';
//import api from '@/util/api.js';

export default {
  name: 'Quoter',
  components:{
    submitButton

  },
  data () {
    return {
      item_counter:0,
      itemAmount:'',
      loading:false,
      items:[],
      itemName: '',
      itemValue:'',
    }
  },

  props:{
    add_text:{
      type:String,
      required:false,
      default:'Agregar'

    },
    placeholder_name:{
      type:String,
      required:false,
      default:'Escribe el nombre del concepto'

    },
    placeholder_amount:{
      type:String,
      required:false,
      default:'Escribe la cantidad'

    },
    value:{},

  },
  mounted () {
    
    for(let i in this.value){

        const it=this.value[i];
        this.items.push({name:it.name,amount:it.amount,quotid:it.id,active:it.active,editing:false});

    }


  },
  methods: {

    
    cancelEdit(index){
        this.items[index].name=this.items[index].aux_name;
        this.items[index].editing=false;

    },
    editItem (index) {

        this.items[index].aux_name=this.items[index].name;
        this.items[index].editing=true;
    },
    
    updateItem (index) {
        
        
        this.items[index].editing=false;

    },

    addItem(){


        this.items.push({name:this.itemName,amount:this.itemAmount,id:this.item_counter++,active:1,editing:false});
        this.itemName='';
        this.itemAmount='';
        

    },
    
    deleteItem (index) {

        if(confirm('¿Eliminar?')){
            this.items.splice(index,1);
            
        }

    }
  },

  watch:{
    items:{
        handler:function(){
        
            this.$emit('input',this.items);
        },
        deep:true
    }


  }
}
</script>

<style scoped lang="scss">
.todo-list {
}
.list-wrapper ul li .form-check{max-width: 100%;width:100%;}
.task-options{width:20%}

.mright{margin-right:10px}  
.editing-quote input{width:49%;display: inline-block;}

.quoter-list .form-check-label{margin-left:5px}


</style>
