<template>


<div class="row">
    
    <div :class="colsize" v-if="!isHidden('sale_price')">
        <div class="form-group">
            <label>Total <br><strong>${{ $numberFormat(total) }}</strong></label>
            <div class="progress">
                <div class="progress-bar bg-gradient-success" role="progressbar" v-bind:style="'width:100%'" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>


    </div>
    
    <div :class="colsize" v-if="!isHidden('deposit')">
        <div class="form-group">
            <label>Apartado <br><strong>${{ $numberFormat(deposit) }}</strong></label>
            <div class="progress">
                <div class="progress-bar bg-gradient-info" role="progressbar" v-bind:style="'width:'+depositPercent+'%'" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    </div>


    <div :class="colsize" v-if="!isHidden('down_payment')">
        <div class="form-group">
            <label>Enganche<br> <strong>${{ $numberFormat(down) }}</strong></label>
            <div class="progress">
                <div class="progress-bar bg-gradient-warning" role="progressbar" v-bind:style="'width:'+downPercent+'%'" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    </div>
    <div :class="colsize" v-if="!isHidden('balance')">
        <div class="form-group">
            <label>{{ textBalance }} <br><strong>${{ $numberFormat(calculateBalance) }}</strong></label>
            <div class="progress">
                <div class="progress-bar bg-gradient-danger" role="progressbar" v-bind:style="'width:'+balancePercent+'%'" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    </div>
</div>


</template>

<script>


export default{
    name:'balance',
    props:{

        hide:{
            default:['down_payment']
        },

        total:{
            type:Number,
            required:true
        },

        deposit:{
            
            require:false


        },

        down:{
            type:Number,
            required:true
        }
    },

    computed:{


        textBalance(){

            return this.isHidden('down_payment')?'Pendiente':'Monto a financiar';

        },

        colsize(){

            let length=4-this.hide.length;

            if(length==3){

                return 'col-md-4 col-lg-4 col-sm-4';
            }

            return 'col-md-3 col-lg-3 col-sm-3';

        },  

        isHidden(){

            return function(i){
                return this.hide.indexOf(i)!=-1?true:false;
            }

        },  

        getDeposit(){


            if(this.hide.indexOf('deposit')==-1){
                return this.$float(this.deposit);

            }
            return 0;
        },

        getDown(){

            
            if(this.hide.indexOf('down_payment')==-1){
                
                return this.$float(this.down);
            }
            return 0;
        },

        calculateBalance(){

            
            const r=this.total-this.getDown-this.getDeposit;
            if(r<0){
                return 0;
            }
            return r;
        },

        downPercent(){
            return this.getDown*100/this.total;
        },

        depositPercent(){
            
                return this.getDeposit*100/this.total;

            

        },

        balancePercent(){

            return this.calculateBalance*100/this.total;


        }

        


    },




}


</script>