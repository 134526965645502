
<template>
<form @submit.prevent="saveQuotation" ref="formQuotation">


    <div class="row">

      <div class="col-md-4 col-sm-4 col-lg-4 min-pad">

            <div class="card">

              <div class="card-body">


                <div class="dev-wrapper">

                  <div class="dev-details" v-if="prop.code">


                    <h3>{{ prop.code }}</h3>
                    
                    <h5>{{ prop.design.name }}</h5>
                    <img :src="$thumb(prop.design.image)"/>
                    <br/>
                    <br/>
                    <h6>Precio: ${{ this.$numberFormat(prop.design.price) }}</h6>
                    <p>Construcción: {{ prop.design.m2inside }} m2</p>
                    <p>Terraza: {{ prop.design.m2outside }} m2</p>
                    <p>Baños: {{ prop.design.bathrooms }}</p>

                  </div>

                  <div v-else>

                      Selecciona una propiedad
                  </div>
                  <br>


                </div>

              </div>

            </div>


            </div>

            <div class="col-md-8 col-sm-8 col-lg-8 min-pad">

                <div class="card">

                  <div class="card-body">

                      <br>

                      <blockquote class="blockquote">

                                 <h5>Precio de venta</h5>

                                 <b-input-group size="lg" prepend="$" v-if="isReady">
                                    
                                    
                                        <input class="form-control form-control-lg sale-price-input" v-number-format v-bind:disabled="!editable_price" v-model.lazy="model.sale_price" type="text"/>
                                    
                                    <b-input-group-append>
                                        <b-input-group-text>
                                            &nbsp;{{ property.design.currency.name }}
                                        </b-input-group-text>
                                        <b-input-group-text>
                                            <a href="javascript:void(0)" v-if="!editable_price" @click="editPrice" class="action-link"><i class="mdi mdi-square-edit-outline"></i></a>
                                            <a href="javascript:void(0)" v-if="editable_price" @click="savePrice" class="action-link"><i class="mdi mdi-check"></i></a>
                                            <a href="javascript:void(0)" v-if="editable_price" @click="cancelEditPrice" class="action-link"><i class="mdi mdi-cancel"></i></a>
                                        </b-input-group-text>

                                    </b-input-group-append>


                                </b-input-group>




                      </blockquote>
                      <blockquote class="blockquote">

                        <div class="form-group">

                          
                          
                                    
                            <h5>Elige la forma de pago</h5>
                                  <div class="prop-payment-selector">
                                          <div class="payment-radio form-check"><label for="cash" class="form-check-label">De contado <input  type="radio" name="paymentoptions" v-model="model.payment_option" value="cash" class="form-check-input" id="cash" checked/><i class="input-helper"></i></label></div>
                                          <div class="payment-radio form-check"><label class="form-check-label" for="credit">A plazos <input  type="radio" name="paymentoptions" class="form-check-input" v-model="model.payment_option" value="credit" id="credit"/><i class="input-helper"></i></label></div>
                                  </div>  


                           


                        </div>
                      </blockquote>


                      <blockquote class="blockquote">
                        <h5>Apartado</h5>
                        <input type="text" v-model="model.deposit" v-number-format class="form-control form-control-lg"/>
                      </blockquote>



                        
                        
                        <blockquote class="blockquote" v-if="isCredit">

                              

                                      <div class="credit-option" >
                                          <div class="form-group">
                                              <h5>Enganche</h5>
                                              <downPayment v-if="isReady" :initial_value="model.down_payment_percentage" :mode="'percent'" @input="onDownPaymentChange"/>
                                          </div>
                                      </div>
                              

                        </blockquote>



                              <blockquote class="blockquote">
                                  <balance :hide="hideOptions" :total="getTotal" :deposit="getDeposit" :down="calculateDown"/>
                              </blockquote>




                              <blockquote class="blockquote" v-if="isCredit">

                                  <div class="row">
                                    <div :class="col_class_amortize">
                                        <h5>Amortizar</h5>
                                        <select class="form-control" v-model="model.amortization_type"> 

                                          <option value="custom">Personalizado</option>
                                          <option value="french">Cuota fija (Sistema francés)</option>
                                          
                                        </select>
                                    </div>
                                    <div :class="col_class_amortize" v-if="isFrench">
                                      <h5>% de interés anual</h5>
                                      <input v-number-format type="text" class="form-control form-control-lg" v-model="model.interest"/>

                                    </div>
                                  </div>


                              </blockquote>



                              <blockquote class="blockquote" v-if="isCredit">

                              <div class="row">


                                  <div class="col-md-6 col-sm-6 col-lg-6">
                                      <h5>Plazo</h5>
                                      <numberCounter @input="onCount" :min="min_count" :max="100" :value="counter"/>
                                  </div>

                                  <div class="col-md-6 col-sm-6 col-lg-6">
                                      <h5>Fecha de inicio de pago</h5>
                                      <Datepicker :position="'top'" :inputClass="'form-control form-control-lg'" lang="es" v-model="model.payment_date" placeholder="Selecciona la fecha"/>
                                      
                                  </div>

                              </div>


                              </blockquote>




                              <blockquote class="blockquote" v-if="isCredit">



                              <div class="installments">

                                  <h5>Calendario de pagos</h5>
                                  <br>
                                  <installments ref="installments" v-if="isReady" @change="onInstallmentChange" :payments="model.payments" :counter="counter" :now="getPaymentDate" :balance="calculateBalance" :annual_interest="model.interest" :amortization_type="model.amortization_type"/>



                              </div>


                        </blockquote>



                      <blockquote class="blockquote">
                          <h5>Costos adicionales</h5>

                          <Quoter v-model="model.extras"/>
                          

                      </blockquote>



                        <blockquote class="blockquote">

                          
                          <h5>Datos del cliente</h5>
                          <br>
                          <formClient ref="formClient"  @model="onClientModel" v-if="isReady" :id="model.client.id" :modelobject="model.client" :showSearch="true" :showSubmit="false"/>
                          
                          
                        </blockquote>

                        
                        <button href="javascript:void(0)" type="submit" class="btn btn-primary"><i class="mdi mdi-content-save-outline"></i> Guardar</button>
                        <a href="javascript:void(0)" :class="savedClass" class="btn btn-primary"><i class="mdi mdi-email-fast-outline"></i> Enviar</a>
                        <a href="javascript:void(0)" :class="savedClass" class="btn btn-primary"><i class="mdi mdi-download"></i> Descargar</a>
                        
                        
                      </div>
                  </div>

                </div>
            </div>


        

  </form>
  
</template>


<script>

import api from '@/util/api.js';
import formClient from '@/pages/clients/_form';
import downPayment from '@/pages/sales/downPayment';
import installments from '@/pages/sales/installments';
import numberCounter from '@/pages/sales/counter';
import balance from '@/pages/quotations/balance';
import Quoter from '@/components/Quoter';

export default{

    name:'formQuotation',
    routeName:'quotations',
    components:{
        formClient,
        downPayment,
        installments,
        numberCounter,
        balance,
        Quoter
        
    },
    data(){


        return {
            loading:false,
            min_count:0,
            counter:0,
            editable_price:false,
            prop:{},
            down_percent:10,
            isSaved:false,
            model:{
                id:0,
                property_id:0,
                client:{},
                down_payment_percentage:15,
                payments:[],
                payment_option:'cash',
                sale_price:0,
                payment_date:new Date(),
                deposit:'5,000',
                extras:{},
                amortization_type:'custom',
                interest:1,
                client_id:0
            },
        }


    },

    props:{

        id:{
            required:false
        },
        development_id:{

            required:false

        },
        property_id:{
            required:false

        },

        property:{

            required:false

        },
        sale_price:{

            required:false

        },

        client:{

            required:false

        }
        


    },

    computed:{



                savedClass(){


                    return this.isSaved?'':'disabled';

                },

                isReady(){

                    return this.id || this.model;

                },

                formReady(){

                return this.model.deposit && (this.model.client_id || this.model.client.id)?'':'disabled';

                },

                isFrench(){

                return this.model.amortization_type=='french'?true:false;

                },


                col_class_amortize(){

                return this.model.amortization_type=='french'?'col-md-6 col-sm-6 col-lg-6':'col-md-12 col-sm-12 col-lg-12';

                },


                hideOptions(){

                return this.isCredit?[]:['down_payment'];

                },
                getDeposit(){

                return this.model.deposit;

                },



                calculateDown(){
                    
                    const price=this.$float(this.model.sale_price);
                    return this.down_percent*price/100;
                    
                },


                getTotal(){

                return this.getExtras+this.getSalePrice;

                },

                getExtras(){

                let total=0;

                for(let i in this.model.extras){
                    const ext=this.model.extras[i];
                    total+=this.$float(ext.amount);
                }


                return total;

                },

                getSalePrice(){

                return this.$float(this.model.sale_price);

                },



                isCash(){
                    return this.model.payment_option=='cash';
                },
                isCredit(){

                    return this.model.payment_option=='credit';
                },

                getPaymentDate(){

                const payment_date=this.model.payment_date;
                if(typeof(payment_date)!='string'){
                    return this.$dateToString(payment_date);
                }
                return payment_date;
                },


                calculateBalance(){

                    const price=this.$float(this.model.sale_price);

                    return (price+this.getExtras)-this.calculateDown-this.$float(this.getDeposit);

                },



    },

    methods:{
        onInstallmentChange(items){
            this.model.payments=items;
        },

        onClientModel(model){
            
            this.model.client_id=model.id;
            this.model.client=model;

        },

        editPrice(){

            this.cached_price=this.model.sale_price;
            this.editable_price=true;
            },
        
        cancelEditPrice(){
            this.model.sale_price=this.cached_price;
            this.editable_price=false;
        },
        
        savePrice(){

            this.editable_price=false;

        },

        onDownPaymentChange(it){

            this.down_amount=it.amount;
            this.down_percent=it.percent;
            this.down_type=it.type;
            this.model.down_payment_percentage=it.percent;
            this.setDownPayment(it);
        },


        onCount(v){
            this.counter=v;
        },


        setDownPayment(it){

        
            if(it.type=='amount'){
                this.model.down_payment=it.amount;
            }else{

                const price=this.$float(this.model.sale_price);
                this.model.down_payment=(this.down_percent*price)/100;
            }



        },

        saveQuotation(){

            

                if(this.validateForm(this.$refs.formQuotation) && this.validateForm(this.$refs.formClient.$refs.formInnerClient)){


                this.model.client.birthday=this.$dateToString(this.model.client.birthday);

                const data=this.createFormData(this.model);


                this.loading=true;
                api.post(this.me('/createquotation'),data).then((response)=>{

                    this.isSaved=true;

                    this.$emit('saved',response);
                    this.$notify({message:'Cotización guardada',type:'success'});

                }).catch().finally(()=>{

                    this.loading=false;

                });

                }


        },


    },


    loadItem(id){

        api.get(this.me('/'+id+'?expand=currency')).then(r=>{
            this.model=r.data;
        });


    },


    mounted(){

        this.prop=this.property;

        this.model.client=this.client;

        

        this.model.development_id=this.development_id;
        this.model.property_id=this.property.id;

        if(this.sale_price){
            this.model.sale_price=this.$numberFormat(this.sale_price);
        }

        if(this.id){

            
            this.loadItem(this.id);
        }

    }



}

</script>